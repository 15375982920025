
import config from "@/app.config"

export default {
  name: "ErrorScreen",

  props: {
    error: { type: String, default: "" }
  },

  created(): void {
    if(config.env === "web") {
      // eslint-disable-next-line no-console
      console.error(this.error)
    } else {
      require("electron-log").error(this.error)
    }
  },

  methods: {
    navBack(): void {
      this.$router.go(-1)
    }
  }
}
